<template>
  <div class="charts-item">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        prop="legged"
        label="指标"
        min-width="140"
        :show-overflow-tooltip="true"
        fixed="left"
      />
      <el-table-column
        v-for="item in column"
        :prop="item.prop"
        :label="item.label"
        width="120"
        :show-overflow-tooltip="true"
        :key="item.prop"
      />
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      column: [],
    };
  },
  watch: {
    "$attrs.chartsUUID"() {
      this.renderTab();
    },
  },
  created() {
    this.renderTab();
  },
  methods: {
    renderTab() {
      let { xData, yData, legged, dbDate } = this.$attrs;
      this.column = xData.map((item) => {
        return {
          prop: item,
          label: item,
        };
      });
      let list = [...legged];
      if (dbDate && dbDate.length) {
        list.push(`${dbDate[0]} ~ ${dbDate[1]}`);
      }
      this.tableData = yData.map((item, i) => {
        let obj = { legged: list[i] };
        xData.forEach((temp, index) => {
          obj[temp] = item.value[index];
        });
        return obj;
      });
      console.log(this.tableData, "yDatayDatayDatayDatayData");
    },
  },
};
</script>
<style lang="scss" scoped>
.charts-item {
  height: 100%;
  padding: 42px 19px 10px;
  overflow-y: auto;
}
</style>
