var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "charts-item" },
    [
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "legged",
              label: "指标",
              "min-width": "140",
              "show-overflow-tooltip": true,
              fixed: "left"
            }
          }),
          _vm._l(_vm.column, function(item) {
            return _c("el-table-column", {
              key: item.prop,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "120",
                "show-overflow-tooltip": true
              }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }